import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Work from "./Pages/Work";
import PageNotFound from "./Pages/PageNotFound";
import WorkSinglePage from "./Components/WorkSinglePage";
import AboutUs from "./Pages/AboutUs";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/work" element={<Work />} />
        <Route path="/work/:slug" element={<WorkSinglePage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
