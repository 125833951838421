import React from 'react'

const WorkCardHomepage = ({ img, title, country }) => {

    return (
        <>
            <div className="wrapper workcard relative mx-auto">
                <div className="w-64 h-64 relative flex flex-col items-center group">
                    <div className="bg-color absolute bg-[#f46a8628] w-full h-full rounded-full -top-12 scale-75 opacity-0 group-hover:opacity-100 group-hover:scale-100 duration-500 cursor-pointer"></div>
                    <div className="icon rounded-full overflow-hidden ">
                        <img className='w-10' src="https://api.housesof.world/assets/302574d5-25e7-46e0-8b8e-d766f8fd9ba0?key=square-small-jpg" alt="" />
                    </div>
                    <div className="name text-[#ff6c89] font-light text-3xl mt-5 text-center">{title}</div>
                    <div className="name text-white font-medium text-xl">{country}</div>
                    <div className="images-workcard absolute -translate-y-24 group-hover:-translate-y-32 duration-700 opacity-0 group-hover:opacity-100">
                        <img className='rounded-2xl' src={img} alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default WorkCardHomepage
