import React from 'react'
import Layout from '../Components/Layout/Layout'
import aboutus from '../images/aboutus.jpg'

const AboutUs = () => {
    return (
        <Layout>
            <div className="wrapper w-full h-full">
                <div className="images relative w-full h-96">
                    <img className='w-full h-full' src={aboutus} alt="" />
                    <div className="absolute top-20 text-white text-5xl left-[44%]">About US</div>
                </div>
            </div>
        </Layout>
    )
}

export default AboutUs
