import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout/Layout";
import Slider from "../Components/Slider";
import testimonal from "../TestimonalDatabase";
import TestimonalCard from "../Components/Cards/TestimonalCard";
import CompanyLogo from '../CompanyLogo'
import CompanyLogo1 from '../CompanyLogo1'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import WorkCardHomepage from "../Components/Cards/WorkCardHomepage";
import service1 from '../images/service1.png'
import service2 from '../images/service2.png'
import service3 from '../images/service3.png'
import service4 from '../images/service4.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import waasho from '../images/waashot.jpg'
import slaywithus from '../images/slaywithust.jpg'
import dental from '../images/dentalt.jpg'
import school from '../images/schoolt.jpg'
import mayaloom from '../images/mayaloomt.jpg'

const Home = () => {
    // AOS setup
    useEffect(() => {
        AOS.init();
    }, [])

    const logoResponsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    // const [scrollStep, setScrollStep] = useState(0);
    // const [fontSize, setFontSize] = useState("9rem");
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            // Determine the scroll position
            const scrollY = window.scrollY || window.pageYOffset;

            // Set showContent to true when scroll position is greater than a certain value (e.g., 100)
            setShowContent(scrollY > 310);
        };

        // Attach the scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Detach the scroll event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const scrollY = window.scrollY || window.pageYOffset;

    //         // Determine the scroll step based on your design
    //         const newScrollStep = Math.floor(scrollY / 20); // Adjust as needed

    //         // Update the font size only if the scroll step changes
    //         if (newScrollStep !== scrollStep) {
    //             const newFontSize = `calc(9rem - ${newScrollStep * 0.4}rem)`;
    //             setFontSize(newFontSize);
    //             setScrollStep(newScrollStep);
    //         }
    //     };

    //     window.addEventListener("scroll", handleScroll);

    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, [scrollStep]);

    return (
        <Layout>
            <div className="sticky top-0 left-0 ">
                <Slider />
            </div>

            <div className="w-full bg-red-500">
                <div
                    className={`fixed top-1 left-10 w-28 h-28 flex justify-center items-center  -z-10 ${showContent ? "block" : "hidden"
                        }`}
                >
                    {/* <img src={logo} alt="logo" /> */}
                </div>
            </div>
            {/* <div className={"title font-semibold -z-10 text-white text-center h-screen flex items-center justify-center"} style={{ fontSize }}>
                <p className={`${showContent ? 'hidden' : 'block'}`}>Digital Deta</p>
            </div> */}

            <div className="">
                {/* About us */}
                <div className="aboutus playpenfont sticky overflow-hidden top-14 left-0 h-screen text-xl lg:text-[1.8rem] lg:leading-[3.7rem] leading-[1.7rem] px-4 lg:px-16 text-center pt-16 scrollshado bg-[#382f7d] text-white lg:mt-96">
                    <p data-aos="zoom-out-left" data-aos-duration="2000" > Elevate your brand with our AI-infused solutions. Targeting the right audience, boosting brand visibility, and enhancing user experiences, our services ensure success metrics surge. <br /> From personalised content creation to immersive web designs, we seamlessly blend AI sophistication and creative prowess. Embrace precision, personalization, and visually stunning experiences - your key to conquering marketing challenges. <br /> <span className="lg:text-[2.1rem] tenorfont"><b>Welcome to the future of strategic AI-driven marketing</b></span>.</p>
                </div>

                <div className="main-conten bg-white relative top-96 scrollshadowwhite">
                    {/* <div className="bgvideo absolute top-0 right-0 w-full  h-full">
                        <img src={bgvideo} alt="" className="w-full h-full" />
                    </div> */}
                    {/* services */}
                    <div className="text-4xl flex flex-col ">
                        <div className="overflow-hidden">
                            <div data-aos="zoom-out-down" data-aos-duration="3000" className=" text-center bubblegumfont text-5xl overflow-hidden underline decoration-2 underline-offset-8"><p>Our Services</p></div>
                        </div>
                        <div data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="3000" className="text-center overflow-hidden juliusfont text-4xl px-9 pt-12 z-[999] ">
                            <p> our services are a strategic blend of AI sophistication and creative prowess, aimed at solving specific challenges faced by our clients. Embrace the future of marketing with precision, personalization, and visually stunning experiences.</p>
                        </div>

                        <div className="main-wrapper overflow-hidden mt-12">
                            {/* First services */}
                            <div className="wrapper bg-[#000000f4] w-full h-full pb-5 lg:pb-0 lg:h-[100vh] lg:flex">
                                <div className="left-side flex justify-center items-center lg:w-1/2">
                                    <div data-aos="fade-right" data-aos-duration="3000" className="w-full p-3 lg:px-16">
                                        <img className="" src={service1} alt="" />
                                    </div>
                                </div>
                                <div className="right-side lg:w-1/2 text-white h-full flex justify-center items-center flex-col px-2 lg:px-9">
                                    <div data-aos="zoom-out-down" data-aos-duration="3000" className="title encodefont tracking-wider pb-6 lg:pb-16 text-center text-2xl lg:text-4xl">Strategic Marketing Using AI</div>
                                    <div className="">
                                        <div className="sub-h text-center font-semibold pb-5 bubblegumfont tracking-wider text-[#F5DB1E]">Inefficient targeting and low conversion rates due to guesswork</div>
                                        <div data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="3000" className="text-2xl text-center portlligatfont">Our AI-powered strategies analyse vast datasets to precisely target your audience, ensuring your marketing efforts are hitting the right mark. Say goodbye to uncertainties and hello to data-driven precision, leading to a surge in success metrics</div>
                                    </div>
                                </div>
                            </div>
                            {/* second services */}
                            <div className="wrapper bg-[#16364C] w-full h-full pb-5 lg:pb-0 lg:h-[100vh] lg:flex">
                                {/* for mobile */}
                                <div className="left-side lg:hidden flex justify-center items-center lg:w-1/2">
                                    <div data-aos="fade-left" data-aos-duration="3000" className="w-full p-3 lg:px-16">
                                        <img className="" src={service2} alt="" />
                                    </div>
                                </div>
                                {/* content for all */}
                                <div className="right-side lg:w-1/2 text-white h-full flex justify-center items-center flex-col px-2 lg:px-9">
                                    <div data-aos="zoom-out-down" data-aos-duration="3000" className="title encodefont tracking-wider pb-6 lg:pb-16 text-center text-2xl lg:text-4xl">Lead & Automated Content Generation Using AI</div>
                                    <div className="">
                                        <div className="sub-h bubblegumfont text-center font-semibold pb-5 tracking-wider text-[#EDB35B]">Struggling to connect with the audience on a personal level</div>
                                        <div data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="3000" className="text-xl text-center portlligatfont">Our AI-driven lead generation and content creation solutions create personalised content that resonates with your audience. From customised emails to social media posts, we ensure your brand's voice is heard loud and clear, fostering meaningful connections and boosting visibility</div>
                                    </div>
                                </div>
                                {/* for laptop */}
                                <div className=" hidden left-side lg:flex justify-center items-center lg:w-1/2">
                                    <div data-aos="fade-left" data-aos-duration="3000" className="w-full p-3 lg:px-16">
                                        <img className="" src={service2} alt="" />
                                    </div>
                                </div>
                            </div>
                            {/* Third services */}
                            <div className="wrapper bg-[#000000] w-full h-full pb-5 lg:pb-0 lg:h-[100vh] lg:flex">
                                <div className="left-side flex justify-center items-center lg:w-1/2">
                                    <div data-aos="fade-right" data-aos-duration="3000" className="w-full p-3 lg:px-16">
                                        <img className="scale-150" src={service3} alt="" />
                                    </div>
                                </div>
                                <div className="right-side lg:w-1/2 text-white h-full flex justify-center items-center flex-col px-2 lg:px-9">
                                    <div data-aos="zoom-out-down" data-aos-duration="3000" className="title encodefont tracking-wider pb-6 lg:pb-16 text-center text-2xl lg:text-4xl">Web and App Development</div>
                                    <div className="">
                                        <div className="sub-h text-center font-semibold pb-5 bubblegumfont tracking-wider text-[#0D9FA6]">High bounce rates and low conversion rates due to a subpar user experience</div>
                                        <div data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="3000" className="text-2xl text-center portlligatfont">Our expert team specialises in crafting not just visually stunning but highly functional websites and apps. The result? A seamless user interface and an immersive user experience that not only captivate visitors but also turn them into satisfied customers, significantly boosting conversion rates</div>
                                    </div>
                                </div>
                            </div>
                            {/* Fourth services */}
                            <div className="wrapper bg-[#CC6728] w-full h-full pb-5 lg:pb-0 lg:h-[100vh] lg:flex">
                                {/* for mobile */}
                                <div className="lg:hidden left-side flex justify-center items-center lg:w-1/2">
                                    <div data-aos="fade-left" data-aos-duration="3000" className="w-full p-2 lg:px-16">
                                        <img className="" src={service4} alt="" />
                                    </div>
                                </div>
                                {/* content for all */}
                                <div className="right-side lg:w-1/2 text-white h-full flex justify-center items-center flex-col px-2 lg:px-9">
                                    <div data-aos="zoom-out-down" data-aos-duration="3000" className="title encodefont tracking-wider pb-6 lg:pb-16 text-center text-2xl lg:text-4xl">UI/UX Design & AI-Generated Graphics Creation</div>
                                    <div className="">
                                        <div className="sub-h bubblegumfont text-center font-semibold pb-5 tracking-wider text-[#F6DA52]">Lack of a captivating online presence and inconsistent brand identity</div>
                                        <div data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="3000" className="text-xl text-center portlligatfont">Our services extend beyond conventional boundaries. We not only create visually appealing websites and logos but also leverage AI to generate graphics that elevate your brand aesthetics. The outcome is a cohesive and captivating online presence that not only attracts but also leaves a lasting impression, solidifying your brand identity</div>
                                    </div>
                                </div>
                                {/* for laptop */}
                                <div className=" hidden left-side lg:flex justify-center items-center w-1/2">
                                    <div data-aos="fade-left" data-aos-duration="3000" className="w-full px-16">
                                        <img className="" src={service4} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* All Projects */}
                    <div className="wrapper flex flex-col justify-center items-center bg-[#382f7d] mb-9">
                        <div className="text-center bubblegumfont text-5xl overflow-hidden underline decoration-2 underline-offset-8 pb-5 text-white pt-8">Our Works</div>
                        <div className=" z-50 w-full">
                            <div className="flex flex-wrap gap-2 pt-9 justify-center items-center lg:px-72">
                                <WorkCardHomepage img={waasho} title="Waasho" country="India" />
                                <WorkCardHomepage img={slaywithus} title="Slay With Us" country="India" />
                                <WorkCardHomepage img={dental} title="3S Dental Care" country="India" />
                            </div>
                            <div className="flex flex-wrap lg:px-36 -pt-12">
                                <WorkCardHomepage img={mayaloom} title="Maya Looms & Craft" country="India" />
                                <WorkCardHomepage img={school} title="Happy Hours School" country="India" />
                                <WorkCardHomepage />
                                <WorkCardHomepage />
                            </div>
                            <div className="flex flex-wrap lg:px-72 -pt-12">
                                <WorkCardHomepage />
                                <WorkCardHomepage />
                                <WorkCardHomepage />
                            </div>
                        </div>
                    </div>

                    <div className="w-full mb-16 mx-auto">
                        <Carousel responsive={logoResponsive}
                            arrows={false}
                            autoPlay={true}
                            infinite={true}
                            rtl={true}
                        >
                            {CompanyLogo.map((data, index) => {
                                return (
                                    <div className="flex w-full flex-wrap items-center justify-center gap-2 mx-auto">
                                        <div className="w-full mx-auto">
                                            <img key={index} className="mx-auto" src={data.imgs} alt="" />
                                            <div className="text-xl text-center w-full font-semibold text-gray-400">{data.name}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>
                    </div>
                    <div className="w-full mb-16 mx-auto">
                        <Carousel responsive={logoResponsive}
                            arrows={false}
                            autoPlay={true}
                            infinite={true}
                            autoPlaySpeed={2000}
                        >
                            {CompanyLogo1.map((data, index) => {
                                return (
                                    <div className="flex w-full flex-wrap items-center justify-center gap-2 mx-auto">
                                        <div className="w-full mx-auto">
                                            <img key={index} className="mx-auto" src={data.imgs} alt="" />
                                            <div className="text-xl text-center w-full font-semibold text-gray-400">{data.name}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>
                    </div>

                    {/* Testimonal */}
                    <div className="wrapper flex flex-col justify-center items-center py-5">
                        <div className="text-center bubblegumfont text-5xl overflow-hidden underline decoration-2 underline-offset-8">What our clients say</div>
                    </div>
                    <div className="my-6 px-9 pb-20">
                        <Carousel responsive={responsive}>
                            {testimonal.map((data, index) => {
                                return (
                                    <TestimonalCard key={index} data={data} />
                                )
                            })}
                        </Carousel>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Home;
