import React from 'react'
import rating from '../../images/starRating.webp'
import { TbWorld } from "react-icons/tb";

const TestimonalCard = ({ data }) => {
    return (
        <>
            <div className="wrapper bg-[#F6F6F8] mx-3 rounded-2xl overflow-hidden text-center pb-4">
                <div className="image h-60 overflow-hidden">
                    <img src={data.image} alt="" />
                </div>
                <div className="rating w-full h-12 mx-auto flex items-center justify-center">
                    <img className='w-32' src={rating} alt="rating" />
                </div>
                <div className="description px-4">{data.desc}</div>
                <div className="readmore text-slate-500 mb-3 cursor-pointer">Read more</div>
                <div className="name font-bold">{data.name}</div>
                <div className="company flex justify-center items-center gap-2 py-2"><TbWorld />{data.company}</div>
            </div>

        </>
    )
}

export default TestimonalCard
