const logo1 = [
  {
    imgs: require("./images/APNADESILogo.png"),
    name: "Apna Desi Store",
  },
  {
    imgs: require("./images/INVESTMENTLogo.png"),
    name: "Investment Unblocked Club",
  },
  {
    imgs: require("./images/MINDOSELogo.png"),
    name: "Min Dose",
  },
  {
    imgs: require("./images/capgainLogo.png"),
    name: "Capgain India",
  },
  {
    imgs: require("./images/happyhousschoolLogo.png"),
    name: "Happy Hour School",
  },
  {
    imgs: require("./images/washo.png"),
    name: "Waasho",
  },
];

export default logo1;
