const logo = [
  {
    imgs: require("./images/dentalLogo.png"),
    name: "3S Dental Care",
  },
  {
    imgs: require("./images/mayahandloomsLogo.png"),
    name: "Maya Looms And Crafts",
  },
  {
    imgs: require("./images/slaywithusLogo.png"),
    name: "Slay With Us",
  },
  {
    imgs: require("./images/pradhancivialLogo.png"),
    name: "Pradhan Construction",
  },
  {
    imgs: require("./images/prayasLogo.png"),
    name: "Prayas",
  },
  {
    imgs: require("./images/jigyansamohantyLogo.png"),
    name: "Jigyansa Mohanty",
  },
  {
    imgs: require("./images/spandanLogo.png"),
    name: "Spandan Dental Care",
  },
];

export default logo;
