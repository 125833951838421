const allWork = [
  {
    id: 1,
    slug: "waasho",
    thumbnail: require("./images/waashot.jpg"),
    mainImage: require("./images/waasho.jpg"),
    image1: require("./images/waasho1.jpg"), // first side image
    image2: require("./images/waasho2.jpg"), // second side image
    image3: require("./images/waasho.jpg"), // full length image sction
    title: "Waasho",
    tag: "Water less car wash",
    client: "Waasho",
    date: "Oct 2023",
    story1:
      "Transforming the car care game with innovation and eco-conscious commitment!",
    story2:
      "🌿🚗Waasho, a premier Waterless Carwash Company, had a vision: redefine automotive care with a sustainable touch. 🌍 We stepped up by crafting their website in React, creating a cutting-edge booking system that's a lead magnet, and shouting out loud, Say Goodbye to Wasting Water! 💦 Our waterless tech not only preserves gallons but leaves vehicles gleaming without harming the planet. 🌟 Passion meets practicality as we revolutionize car cleaning—spotless cars, greener future! 🌱 Join us in breaking barriers, one waterless wash at a time.",
    story3: "",
    story4: "",
    link:"https://waasho.in/"
  },
  {
    id: 2,
    slug: "slaywithus",
    thumbnail: require("./images/slaywithust.jpg"),
    mainImage: require("./images/slayiwithus.jpg"),
    image1: require("./images/slaywithus1.jpg"), // first side image
    image2: require("./images/slaywithus2.jpg"), // second side image
    image3: require("./images/slayiwithus.jpg"), // full length image sction
    title: "Slaywithus",
    tag: "Booking your events",
    client: "Slaywithus",
    date: "Nov 2023",
    story1:
      "Embark on a fashion journey with Jigyansa and Mithila, the dynamic duo behind our client's revolutionary concept of Virtual Styling. These trendsetting sisters, Jigyansa a banker and Mithila an HR consultant, seamlessly blend their love for fashion with a knack for writing. Originating from Bhubaneswar and traversing through Maharashtra, the United States, and the United Kingdom, they bring a global perspective to their virtual styling venture.",
    story2:
      "Our mission? To turn their vision of a Fashionable India for women aged 18-60+ into a reality. Tackling the ubiquitous dilemma of What to wear, we've crafted a unique online experience. Our React-powered websites provide a stylish interface, while our filtered booking system ensures a pipeline of high-quality leads. The pièce de résistance? A one-on-one consultation system for fashion blogging, personalized and transformative.",
    story3:
      "In 2020, Jigyansa authored STAGGERED, an online book, showcasing not only their fashion prowess but also our commitment to making their dream a vibrant, stylish reality. Elevate your style, embrace the virtual fashionistas' vision, and let our solutions redefine your fashion narrative.",
    story4: "",
    link:"https://slaywithus.com/"
  },
  {
    id: 3,
    slug: "happy-hours-school",
    thumbnail: require("./images/schoolt.jpg"),
    mainImage: require("./images/school.jpg"),
    image1: require("./images/school1.jpg"), // first side image
    image2: require("./images/school2.jpg"), // second side image
    image3: require("./images/school.jpg"), // full length image sction
    title: "Happy Hours School",
    tag: "Join with us",
    client: "Happy Hours School",
    date: "Nov 2022",
    story1:
      "At Happy Hours School, breaking barriers is our game, and we've turned education into a rocking adventure! 🚀 Our clients dreamt of a foundation school in Odisha where Fun leads to learning, and we made that dream a reality. No pressure, just pleasure in acquiring knowledge—that's the Happy Hours philosophy!",
    story2:
      "Our mission? We kick-started their journey by crafting sleek WordPress websites, ensuring they stand out in the digital playground. 🌐 Need leads? We introduced a snazzy payment and inquiry system, turning curious minds into enrolled students seamlessly. Happy Hours Play School? We proudly call it Your Door To The Future. 🚪💡",
    story3:
      "Why are we the game-changers? Because we don't just build websites; we build futures. Our solutions are not just unique; they're transformative. We broke the social barriers, creating a space where learning is as cool as it gets. Cheers to Happy Hours—where education meets excitement! 🌟",
    story4: "",
    link:"https://happyhoursbbsr.com/"
  },
  {
    id: 4,
    slug: "maya-looms-and-crafts",
    thumbnail: require("./images/mayaloomt.jpg"),
    mainImage: require("./images/mayaloom.jpg"),
    image1: require("./images/mayaloom1.jpg"), // first side image
    image2: require("./images/mayaloom.jpg"), // second side image
    image3: require("./images/mayaloom.jpg"), // full length image sction
    title: "Maya Looms And Crafts",
    tag: "Looms And Crafts",
    client: "Maya Looms And Crafts",
    date: "Oct 2023",
    story1:
      "Embark on a journey through the vibrant tapestry of Odisha with our client's vision - to bring the artistry of handwoven marvels to the world. From sarees to upcycled accessories, we ship a piece of Odisha's heart globally, all handwoven with love and dedication by skilled Odisha weavers. We played a pivotal role in realizing their dreams",
    story2:
      "Revolutionizing Web Presence: Crafted sleek, user-friendly WordPress websites that scream authenticity and showcase their products seamlessly.",
    story3:
      "E-commerce Brilliance: Designed a cutting-edge e-commerce platform, making it a breeze for customers to indulge in the beauty of Odisha's creations, no matter where they are. maya looms and crafts Unveiling Colors of Culture: We birthed maya looms and craftsl, a campaign turning handloom hues into a mirror reflecting India's future.",
    story4:
      "In breaking social barriers, we didn't just create websites; we wove a digital narrative, connecting artisans with a global audience. Our solutions aren't just unique; they're transformative, giving our client a global voice in the rich tapestry of Odisha's heritage.",
      link:"https://mayaloomsandcrafts.com/"
  },
  {
    id: 5,
    slug: "dental-care",
    thumbnail: require("./images/dentalt.jpg"),
    mainImage: require("./images/dental.jpg"),
    image1: require("./images/dental1.jpg"), // first side image
    image2: require("./images/dental2.jpg"), // second side image
    image3: require("./images/dental1.jpg"), // full length image sction
    title: "3S Dental Care",
    tag: "Dental Care",
    client: "Dental Care",
    date: "jun 2023",
    story1:
      "Welcome to the heart of dental excellence - Sai Shradha Smile Dental Care! 🌟 As one of Bhubaneswar's premier clinics, we've pioneered a new era in oral health. Picture this: 5,000+ radiant smiles from across Odisha, all thanks to our ISO certified haven of advanced dental wizardry.",
    story2:
      "Our mission? Simple. We're on a crusade to make top-tier dental care accessible to everyone, ensuring those pearly whites shine without burning a hole in your pocket. At Sai Shradha, we infuse passion into every treatment, spreading joy one smile at a time. 🌈",
    story3:
      "Our bragging rights? Oh, just a few transformative solutions that redefine the dental game. From crafting sleek WordPress websites to being the architects behind 3S Dental Care – a milestone in oral care excellence. We don't just fix teeth; we break social barriers, making dental care cool, affordable, and, dare we say, fun!",
    story4:
      "Join us in the smile revolution because at Sai Shradha, we don't just fix teeth; we craft masterpieces that break the mold. Here's to brighter smiles and breaking the social norm! 🦷💪",
      link:"https://3sdentalcare.in/"
  },
  // {
  //   id: 6,
  //   slug: "capgain-india",
  //   thumbnail: require("./images/slaywithus.png"),
  //   mainImage: require("./images/slaywithus.png"),
  //   image1: require("./images/slaywithus.png"), // first side image
  //   image2: require("./images/slaywithus.png"), // second side image
  //   image3: require("./images/slaywithus.png"), // full length image sction
  //   title: "Capgain India",
  //   tag: "Capgain India",
  //   client: "Capgain India",
  //   date: "Nov 2023",
  //   story1:
  //     "🚀 Breaking barriers in the financial landscape! 🌐 At CapGain India, our mission was crystal clear: Empower clients for smarter investments, ace mutual funds, and navigate the financial terrain with the perfect loans for their unique needs. 💸 Being the backbone of economic growth, we championed policy-making, sculpted innovative revenue models, revamped customer experiences, fostered a robust risk culture, and crafted inclusive strategies.",
  //   story2:
  //     "🌟 How did we make the magic happen? 💼 We revolutionized their online presence with sleek WordPress websites, making finance approachable and relatable. We're not just consultants; we're your finance buddies! 🤝 Check out capgainindia – where sustainable wealth is not just a concept; it's a reality we've crafted together. 🚀 Ready to break financial barriers? Your journey starts here! ",
  //   story3:
  //     "In 2020, Jigyansa authored STAGGERED, an online book, showcasing not only their fashion prowess but also our commitment to making their dream a vibrant, stylish reality. Elevate your style, embrace the virtual fashionistas' vision, and let our solutions redefine your fashion narrative.",
  //   story4: "",
  // },
  // {
  //   id: 7,
  //   slug: "investment-unblocked-club",
  //   thumbnail: require("./images/slaywithus.png"),
  //   mainImage: require("./images/slaywithus.png"),
  //   image1: require("./images/slaywithus.png"), // first side image
  //   image2: require("./images/slaywithus.png"), // second side image
  //   image3: require("./images/slaywithus.png"), // full length image sction
  //   title: "Investment Unblocked Club",
  //   tag: "Investment Unblocked Club",
  //   client: "Investment Unblocked Club",
  //   date: "jan 2023",
  //   story1:
  //     "Embark on a financial journey with Investment Unblocked Trading Club, where we've shattered the norm and ushered in a new era of trading success! Our clients envisioned a seamless blend of expert insights, cutting-edge tools, and personalized support to conquer the stock market. Guess what? We made it happen! With a WordPress website as our canvas, we crafted the Best Investment Learning Solution, offering not just guidance, but a transformative experience. Picture this: a staggering 98% success rate thanks to our Expert Advisor, coupled with the power of Technical Analysis and a dash of free consultations. We've not just met but exceeded goals, paving the way for multifold returns. Join the club that breaks barriers and propels you towards financial triumph! 🚀💰 #InvestmentUnblocked #FinancialSuccess #BreakingBarriers",
  //   story2: "",
  //   story3: "",
  //   story4: "",
  // },
  // {
  //   id: 8,
  //   slug: "the-solutions-infra",
  //   thumbnail: require("./images/happy_hour_school.png"),
  //   mainImage: require("./images/happy_hour_school.png"),
  //   image1: require("./images/happy_hour_school.png"), // first side image
  //   image2: require("./images/happy_hour_school.png"), // second side image
  //   image3: require("./images/happy_hour_school.png"), // full length image sction
  //   title: "The Solutions Infra",
  //   tag: "thesolutionsinfra",
  //   client: "The Solutions Infra",
  //   date: "Aug 2022",
  //   story1:
  //     "Elevating the exam game to new heights! 🚀 At Simplified Solutions Infra, we're not just about exams; we're about rewriting the rules. 🌐 Our vision? To revolutionize the examination industry with rock-solid support and game-changing guidance. We believe in innovation, delivering spot-on solutions at the right time and place. 🎯 Our track record? Impeccable. From crafting sleek WordPress websites to seamlessly blending top-notch people, foolproof processes, and cutting-edge technology. 💻✨ With over 5 years of hands-on experience, we've become the go-to for diverse exam-related services across multiple metropolises. 🏙️ We've shattered barriers, serving both public and private clients, collaborating with exam boards, and powering recruitment bodies. 💪 Ready to break through with us? Your exam journey is about to get a whole lot smarter and smoother! 🚀📚 #InnovateWithSSI #ExamRevolution #BreakingBarriers",
  //   story2: "",
  //   story3: "",
  //   story4: "",
  // },
  // {
  //   id: 9,
  //   slug: "somis-stay",
  //   thumbnail: require("./images/waasho.jpg"),
  //   mainImage: require("./images/waasho.jpg"),
  //   image1: require("./images/waasho.jpg"), // first side image
  //   image2: require("./images/waasho.jpg"), // second side image
  //   image3: require("./images/waasho.jpg"), // full length image sction
  //   title: "Somis stay",
  //   tag: "Somis stay",
  //   client: "Somis stay",
  //   date: "Nov 2023",
  //   story1:
  //     "Embark on a journey with Somi's Stay – where we transformed visions into reality, breaking through social barriers to redefine hospitality. Our clients dreamt of a luxurious oasis, a haven of unparalleled experiences. We crafted their digital footprint with sleek, responsive WordPress websites that not only showcase sophistication but also invite engagement. Discovering the perfect blend of opulence and unforgettable moments, we turned their goals into a thriving reality. With us, booking a stay isn't just a transaction; it's an immersion into lavish accommodations, delightful dining, and unbeatable amenities. At Somi's Stay, we shattered norms, offering transformative solutions that go beyond the expected. Contact us now to experience the extraordinary, where every stay is a testament to breaking free from the ordinary. Your oasis of hospitality awaits - let's redefine luxury together.",
  //   story2: "",
  //   story3: "",
  //   story4: "",
  // },
  // {
  //   id: 10,
  //   slug: "spandan dental",
  //   thumbnail: require("./images/waasho.jpg"),
  //   mainImage: require("./images/waasho.jpg"),
  //   image1: require("./images/waasho.jpg"), // first side image
  //   image2: require("./images/waasho.jpg"), // second side image
  //   image3: require("./images/waasho.jpg"), // full length image sction
  //   title: "Spandan Dental",
  //   tag: "Spandandental",
  //   client: "Spandandental",
  //   date: "Nov 2023",
  //   story1:
  //     "Embark on a smile transformation journey with Spandan Dental Clinic in Bhubaneswar! Our vision was simple: redefine dental services by blending innovation, personalized care, and a commitment to creating beautiful, confident smiles. With a dynamic team of over 100 professionals, we're not just a clinic; we're agents of long-term change for our patients.",
  //   story2: "What makes us stand out? It's the Spandan touch: hassle-free booking, quality and friendly dentists, and a unique procedure flow that ensures your comfort from the first appointment to check-up and payment. We've broken the social barrier associated with dental care by prioritizing your specific needs, infusing cutting-edge technology with an aesthetic eye, and crafting an exceptional patient experience. ",
  //   story3: "Join us for a dental journey where perfection meets dedication, leaving you with a smile that exceeds expectations. Trust Spandan Dental Clinic for the confidence and brilliance you deserve!",
  //   story4: "",
  // },
  // {
  //   id: 11,
  //   slug: "dhanwantaree",
  //   thumbnail: require("./images/waasho.jpg"),
  //   mainImage: require("./images/waasho.jpg"),
  //   image1: require("./images/waasho.jpg"), // first side image
  //   image2: require("./images/waasho.jpg"), // second side image
  //   image3: require("./images/waasho.jpg"), // full length image sction
  //   title: "Dhanwantaree",
  //   tag: "Dhanwantaree",
  //   client: "Dhanwantaree",
  //   date: "Nov 2023",
  //   story1:
  //     "Revolutionizing healthcare at Dhanwantaree Clinic in Bhubaneswar! With a mission to make lives better, we specialize in providing exceptional care for the sickest in the community. Boasting over 25 years of combined experience, our clinic, led by the renowned Dr. Ashoak Acharya, is recognized for its excellence in minimally invasive surgery. Dr. Acharya, a board-certified surgeon, is a leader in the field, ensuring individual focus and attention to every patient. ",
  //   story2: "Breaking the social barrier, we're not just a clinic; we're a health care community offering state-of-the-art facilities, cutting-edge technology, and expertise. Our vision? To provide convenient hours, expert care, and a spirit to heal, all delivered by a qualified team of professionals. From trauma surgery to 24*7 healthcare, our transformative solutions redefine quality care in Bhubaneswar. Join us in celebrating a journey of health, compassion, and excellence!  ",
  //   story3: "",
  //   story4: "",
  // },
  // {
  //   id: 12,
  //   slug: "mscaterer",
  //   thumbnail: require("./images/waasho.jpg"),
  //   mainImage: require("./images/waasho.jpg"),
  //   image1: require("./images/waasho.jpg"), // first side image
  //   image2: require("./images/waasho.jpg"), // second side image
  //   image3: require("./images/waasho.jpg"), // full length image sction
  //   title: "Mscaterer",
  //   tag: "Mscaterer",
  //   client: "Mscaterer",
  //   date: "Nov 2023",
  //   story1:
  //     "Indulge in the art of celebration with MS Caterers – where enjoyment knows no bounds! Founded by Mukesh Kumar in 2008, our mission revolves around delivering unparalleled catering experiences. We've mastered the delicate balance of affordability without compromising quality. Our success is defined by client satisfaction and a team committed to ethical and professional work culture. ",
  //   story2: "At MS Caterers, we break the social barrier in event management with tailored services for every occasion. From corporate events that impress to intimate naming ceremonies and joyous housewarmings, we infuse each celebration with excellence. Birthdays and anniversaries are elevated with our unique touch, while weddings become unforgettable with our meticulous planning. ",
  //   story3: "MS Caterers isn't just a service; it's a celebration partner, ensuring every event leaves a lasting impression. Experience the epitome of celebration with us. 🎉✨",
  //   story4: "",
  // },
];

export default allWork;
