const testimonal = [
  {
    image: require("./images/testimonal_waasho.jpeg"),
    desc: "Love the design and customization of InstaShow 👍. We have used various Instagram apps for Shopify in the past but they would",
    name: "Vivek Berman",
    company: "Waasho",
  },
  {
    image: require("./images/testimonal_slaywithus1.jpeg"),
    desc: "Love the design and customization of InstaShow 👍. We have used various Instagram apps for Shopify in the past but they would",
    name: "Jigyansa Mohanty",
    company: "Slay With Us",
  },
  {
    image: require("./images/testimonal_3sdentalCare.jpeg"),
    desc: "Love the design and customization of InstaShow 👍. We have used various Instagram apps for Shopify in the past but they would",
    name: "Dr Sunil Kar",
    company: "3sdental Care",
  },
  {
    image: require("./images/testimonal_Easternghat.jpeg"),
    desc: "Love the design and customization of InstaShow 👍. We have used various Instagram apps for Shopify in the past but they would",
    name: "Rajeeb berma",
    company: "Easternghat",
  },
  {
    image: require("./images/testimonal_apnaDesiStore.jpeg"),
    desc: "Love the design and customization of InstaShow 👍. We have used various Instagram apps for Shopify in the past but they would",
    name: "Bibhuti Rout",
    company: "Apna desi store",
  },
  {
    image: require("./images/testimonal_slaywithus.jpeg"),
    desc: "Love the design and customization of InstaShow 👍. We have used various Instagram apps for Shopify in the past but they would",
    name: "Mithila Mohanty",
    company: "Slay With Us",
  },
];
export default testimonal;
